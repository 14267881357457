





















































































































































































































































import {
  BCard,
  BRow,
  BCol,
  BForm,
  BPagination,
  BTable,
  BModal,
  BFormGroup,
  BFormInvalidFeedback,
  BListGroup,
  BListGroupItem,
  BProgressBar,
  BProgress,
} from "bootstrap-vue";
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { Component, Watch, Vue } from "vue-property-decorator";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import useTable from "@core/utils/useTable";
import { useGeralImport } from "@/services";

// Interfaces
import { IOption, IUserData } from "@core/services/interfaces/IUtil";
import { IBilling } from "@/store/firebase/firebaseState";
import { convertDateTimeToString } from "@/services/utils";
import { Timestamp } from "firebase/firestore";

interface IArchive {
  name: string;
  size: number;
}

@Component({
  name: "ImportEmplacamento",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BPagination,
    BTable,
    AvButton,
    AvSkeletonTable,
    vSelect,
    BModal,
    BListGroup,
    BListGroupItem,
    BProgressBar,
    BProgress,
    AvExportButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
})
export default class ImportEmplacamento extends Vue {
  // Data
  $refs = {
    fileInput: {} as HTMLInputElement,
    refListTable: {} as any,
  };
  tableColumns = [
    { key: "Data", label: "Data de Início", sortable: true },
    { key: "Registros", label: "Registros", sortable: false },
    { key: "Status", label: "Status", sortable: true },
    { key: "Sucesso", label: "Sucesso", sortable: true },
    { key: "Mensagem", label: "Mensagem", sortable: false },
  ];
  formValidation = formValidation();
  modalAddShow: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  uploading = {
    status: false,
    fileName: "",
    progress: 0,
  };
  statusTypeOptions: IOption[] = [];
  perPage = 10;
  pageOptions: number[] = [10, 25, 50, 100];
  totalRows = 1;
  currentPage = 1;
  sortBy = "";
  sortDesc = false;
  items: IBilling[] = [];
  importFile: IArchive = {} as IArchive;

  useTable = new useTable(this.tableColumns, {
    sortBy: "Data",
    isSortDirDesc: true,
  });

  // Computeds
  get billings(): IBilling[] {
    const billings: IBilling[] = this.$store.getters["firebase/billings"];
    const userData: IUserData | null = JSON.parse(
      localStorage.getItem("userData") || ""
    );

    if (userData) {
      return billings.filter((it) => it.GrupoId == userData.groupId);
    } else {
      return [];
    }
  }

  // LifeCycle
  created() {
    this.$store
      .dispatch("firebase/startFirestoreRealTimeTrackingBillings")
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar lista de faturamento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  destroyed() {
    this.$store.dispatch("firebase/stopFirestoreRealTimeTrackingBillings");
  }

  // Watchs
  @Watch("billings")
  fetchBrandValidations() {
    this.useTable.listTable.value = this.billings;
    this.useTable.filterItems();
    this.useTable.firstRequest.value = false;
  }

  // Methods
  getDate(date: Timestamp): string {
    return convertDateTimeToString(date.toDate());
  }

  handlerSidebarAdd() {
    this.modalAddShow = true;
  }

  private isXLSXFile(file: File): boolean {
    const fileName = file.name;
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2); // Obtém a extensão do arquivo

    // Verifica se a extensão é xlsx
    return fileExtension.toLowerCase() === 'xlsx';
  }

  private async renameFile(blob: Blob, newName: string): Promise<File> {
    // Converte o Blob para um ArrayBuffer
    const arrayBuffer = await blob.arrayBuffer();

    // Cria um novo Blob com o ArrayBuffer e o tipo de arquivo original
    const newBlob = new Blob([arrayBuffer], { type: blob.type });

    // Cria um novo objeto File com o novo Blob e o novo nome
    const newFile = new File([newBlob], newName, { type: blob.type });

    return newFile;
  }

  async onSubmit() {
    let file = (this.$refs.fileInput as HTMLInputElement).files?.[0];
    if (file) {

      if (!this.isXLSXFile(file)){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Falha ao fazer upload do arquivo. O arquivo deve ser ter a extensão \'XLSX\'",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }

      file = await this.renameFile(file, "faturamento.xlsx")

      this.saving = true;
      this.uploading.fileName = file.name;
      this.uploading.status = true;
      this.uploading.progress = 0;
      await useGeralImport
        .requestUploadBilling(file, {
          onUploadProgress: (progressEvent) => {
            this.uploading.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Importação de faturamento!",
              text: "A solicitação de importação de faturamento foi realizada com sucesso.",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.modalAddShow = false;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Falha ao fazer upload do arquivo",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.$refs.fileInput.value = "";
          this.uploading.fileName = "";
          this.uploading.status = false;
          this.uploading.progress = 0;
          this.saving = false;
        });
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Preencha todos os campos obrigatórios!",
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    }
  }

  convertDatePtBr = (value: string): string => {
    const date = value.split("T")[0].split("-");

    return `${date[2]}/${date[1]}/${date[0]}`;
  };

  /**
   * Mapeando o valor do select status de um objecto <{label, value}> para apenas value boolean
   */
  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };

  openAddFile() {
    const fileInput = this.$refs.fileInput;
    fileInput.dispatchEvent(new MouseEvent("click"));
  }

  handleFileInputChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.importFile = {
        name: file.name,
        size: Math.ceil(file.size / 1024),
      };
    }
  }

  deleteFile() {
    this.importFile = {} as IArchive;
    this.$refs.fileInput.value = "";
  }

  getImports() {
    return new Promise((resolve, reject) => {
      try {
        const data = this.$refs.refListTable.filteredItems.map(
          (item: IBilling) => ({
            "Data de Início": item.Data ? this.getDate(item.Data) : "",
            Registros: "",
            "Nome do arquivo": "",
            Status: item.Finalizada ? "Finalizada" : "Processando",
            Sucesso: "",
            Mensagem: item.Status,
          })
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
